import axios from "axios";


async function adminGuard(to, from, next){
  if (localStorage.getItem('accesstoken')) {
    try {
      const response = await axios.get('https://admin.beautycentersalon.com/v1/api/auth/checktoken', { headers: { Authorization: `Bearer ${localStorage.getItem('accesstoken')}` } });
      if (response.data.status == 200 && response.data.result.rol == 'admin') {
        next();
      }else{
        next({ name: 'dashboard' });
      }
    } catch (error) {
      if (error.response.data.status == 404) {
        localStorage.removeItem('rol');
        localStorage.removeItem('accesstoken');
        next({ name: 'home' });
      }
    }
     }else{
       localStorage.removeItem('accesstoken');
       localStorage.removeItem('rol');
       next({ name: 'home' });
     }
}

export default adminGuard
