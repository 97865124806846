import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import adminGuard from './admin-guard';
import isAuthenticatedGuard from './auth-guard';
import isAuthGuard from './redirect-guard';

const routes = [
  {
    path: '/',
    name: 'home',
    beforeEnter: [ isAuthGuard ],
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    beforeEnter: [ isAuthenticatedGuard ],
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    beforeEnter: [ isAuthenticatedGuard ],
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/DashboardView.vue')
  },
  {
    path: '/cliente',
    name: 'cliente',
    beforeEnter: [ isAuthenticatedGuard ],
    component: () => import(/* webpackChunkName: "cliente" */ '../views/ClienteView.vue')
  },
  {
    path: '/puntodeventa',
    name: 'puntodeventa',
    beforeEnter: [ isAuthenticatedGuard ],
    component: () => import(/* webpackChunkName: "puntodeventa" */ '../views/PvView.vue')
  },
  {
    path: '/gastos',
    name: 'gastos',
    beforeEnter: [ isAuthenticatedGuard ],
    component: () => import(/* webpackChunkName: "gastos" */ '../views/GastosagendaView.vue')
  },
  {
    path: '/gastospv',
    name: 'gastospv',
    beforeEnter: [ isAuthenticatedGuard ],
    component: () => import(/* webpackChunkName: "gastospv" */ '../views/GastospvView.vue')
  },
  {
    path: '/usuarios',
    name: 'usuarios',
    beforeEnter: [ adminGuard ],
    component: () => import(/* webpackChunkName: "usuarios" */ '../views/UsuariosView.vue')
  },
  {
    path: '/productos',
    name: 'productos',
    beforeEnter: [ adminGuard ],
    component: () => import(/* webpackChunkName: "productos" */ '../views/ProductosView.vue')
  },
  {
    path: '/categorias',
    name: 'categorias',
    beforeEnter: [ adminGuard ],
    component: () => import(/* webpackChunkName: "categorias" */ '../views/CatView.vue')
  },
  {
    path: '/equipo',
    name: 'equipo',
    beforeEnter: [ adminGuard ],
    component: () => import(/* webpackChunkName: "equipo" */ '../views/EquipoView.vue')
  },
  {
    path: '/servicios',
    name: 'servicios',
    beforeEnter: [ adminGuard ],
    component: () => import(/* webpackChunkName: "servicios" */ '../views/ServiciosView.vue')
  },
  {
    path: '/cabina',
    name: 'cabina',
    beforeEnter: [ adminGuard ],
    component: () => import(/* webpackChunkName: "cabina" */ '../views/CabinaView.vue')
  },
  {
    path: '/comisiones',
    name: 'comisiones',
    beforeEnter: [ adminGuard ],
    component: () => import(/* webpackChunkName: "comisiones" */ '../views/ComisionesView.vue')
  },
  {
    path: '/comisionespv',
    name: 'comisionespv',
    beforeEnter: [ adminGuard ],
    component: () => import(/* webpackChunkName: "comisionespv" */ '../views/ComisionespvView.vue')
  },
  {
    path: '/agenda',
    name: 'agenda',
    beforeEnter: [ isAuthenticatedGuard ],
    component: () => import(/* webpackChunkName: "agenda" */ '../views/AgendaView.vue')
  },
  {
    path: '/corte',
    name: 'corte',
    beforeEnter: [ isAuthenticatedGuard ],
    component: () => import(/* webpackChunkName: "corte" */ '../views/CorteView.vue')
  },
  {
    path: '/cortepv',
    name: 'cortepv',
    beforeEnter: [ isAuthenticatedGuard ],
    component: () => import(/* webpackChunkName: "cortepv" */ '../views/CortepvView.vue')
  },
  {
    path: '/cortedia',
    name: 'cortedia',
    beforeEnter: [ adminGuard ],
    component: () => import(/* webpackChunkName: "cortedia" */ '../views/CortediaView.vue')
  },
  {
    path: '/cortediapv',
    name: 'cortediapv',
    beforeEnter: [ adminGuard ],
    component: () => import(/* webpackChunkName: "cortediapv" */ '../views/CortediapvView.vue')
  },
  {
    path: '/gastodia',
    name: 'gastodia',
    beforeEnter: [ adminGuard ],
    component: () => import(/* webpackChunkName: "gastodia" */ '../views/GastoxdiaView.vue')
  },
  {
    path: '/gastodiapv',
    name: 'gastodiapv',
    beforeEnter: [ adminGuard ],
    component: () => import(/* webpackChunkName: "gastodiapv" */ '../views/GastosxdiapvView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
