<template>
<div class="wrapLogin text-center">
    <main class="form-signin w-100 m-auto">
      <form @submit.prevent="login">
        <img class="mb-4" src="../assets/logo.png" alt="" width="200" height="200">
        <div class="form-floating pb-2">
          <input type="text" class="form-control" id="floatingInput" placeholder="Nombre de usuario" v-model="usuario" required>
          <label for="floatingInput">Usuario</label>
        </div>
        <div class="form-floating pb-1">
          <input type="password" class="form-control" id="floatingPassword" placeholder="Contraseña" v-model="pass" required>
          <label for="floatingPassword">Contraseña</label>
        </div>
    
        <button class="w-100 btn btn-lg btn-primary" type="submit">Iniciar sesión</button>
        <p class="mt-5 mb-3 text-muted">&copy; beautycenter</p>
        <p class="mt-5 mb-3 text-muted" v-if="error">Usuario o Contraseña incorrectas.</p>
      </form>
    </main>
</div>

</template>

<script>
import axios from "axios";

export default {
  data() {
        return {
            usuario: null,
            pass: null,
            error: false
        }
    },
    methods: {
        login() {
          const params = new URLSearchParams();

          params.append('usuario', this.usuario);
          params.append('pass', this.pass);

          axios.post('https://admin.beautycentersalon.com/v1/api/auth/login', params)
                .then((response) => {
                  if (response.data.status === 200 && response.data.result.rol === 'admin') {
                    localStorage.setItem('accesstoken', response.data.result.token);
                    localStorage.setItem('rol', response.data.result.rol);
                    this.$router.push('/dashboard')
                  }else{
                    localStorage.setItem('accesstoken', response.data.result.token);
                    this.$router.push('/dashboard')
                  }
                }).catch((error) => {
                  if (error.response.data.status === 404) {
                    this.error = true;
                    console.clear();
                  }
                });
        }
    },
}
</script>

<style scoped>

.wrapLogin {
  display: flex;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
  height: 100%;
}

main{
  box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}
.form-signin {
  max-width: 330px;
  padding: 15px;
}

.form-signin .form-floating:focus-within {
  z-index: 2;
}

.form-signin input[type="text"] {
  margin-bottom: -1px;
}

.form-signin input[type="password"] {
  margin-bottom: 10px;
}

button{
  font-size: 1em;
}
</style>